import { useNavigate, useParams, useRoutes } from "react-router-dom";
import React, { Suspense, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import jwt_decode from "jwt-decode";

// local imports
import { setgCurrentForm } from "../Redux/features/AppUtilsSlice";
import { PrivateRoutes, PublicRoutes } from "./routes";
import Spinner from "../components/common/Spinner";
import FallBackLoading from "../components/common/FallBackLoading";
import {
  getCompantId,
  getToken,
  setTempCompantId,
  verifyToken,
} from "../utils";

import { getUserData, logoutUser } from "../Redux/features/AuthSlice";
import { API } from "../config";

function AppRoutes() {
  let PublicElement = useRoutes(PublicRoutes);
  let PrivateElement = useRoutes(PrivateRoutes);

  const { pathname } = useLocation(); // <-- get current location being accessed
  const dispatch = useDispatch();

  const {
    Auth: { Authenticate, currentUser },
  } = useSelector((state) => state);

  useEffect(() => {
    if (pathname.match("inspection-plan")) {
      dispatch(setgCurrentForm("/inspection-plan/view/"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  useEffect(() => {
    //console.log("current pathname :", pathname);
    return () => {};
  }, [pathname]);

  const navigate = useNavigate();
  const companyId = getCompantId();
  const TOKEN = getToken();
  const params = useParams();

  // console.log("params --->", params);

  useEffect(() => {
    if (verifyToken()) {
      setTempCompantId(companyId, TOKEN);
      var { exp } = jwt_decode(TOKEN);
      if (Date.now() >= exp * 1000) {
        dispatch(logoutUser());
      }
      if (Object.keys(currentUser).length === 0) {
        dispatch(getUserData({ token: TOKEN, navigate, from: pathname }));
      }
    }

    if (!verifyToken()) {
      dispatch(logoutUser());
      if (pathname.match("/regPage")) {
        navigate("/regPage");
      } else if (pathname.match("/forgot")) {
        navigate("/forgot");
      } else if (pathname.match("/compReg")) {
        navigate("/compReg");
      } else if (pathname.match("/newPass")) {
        navigate("/newPass");
      } else {
        // console.log("params -->", params);
        navigate("/login", {
          state: { from: pathname, authKey: "token-none" },
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Authenticate, pathname]);

  useEffect(() => {
    API.settings.companyId();
    return () => {};
  }, [companyId]);

  useEffect(() => {
    if (pathname.match("inspection-plan")) {
      // //console.log("Location changed : ", pathname);
      dispatch(setgCurrentForm("/inspection-plan/view/"));
    }
  }, [pathname]);

  return (
    <>
      {!Authenticate ? (
        <Suspense fallback={<Spinner />}>{PublicElement}</Suspense>
      ) : (
        <Suspense fallback={<FallBackLoading />}>{PrivateElement}</Suspense>
      )}
    </>
  );
}

export default AppRoutes;
