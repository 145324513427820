import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";
import { API } from "../../config";
import { ERROR_MESS } from "../../constant";
import {
  removeCompantId,
  removeToken,
  setCompantId,
  setToken,
} from "../../utils";
import { SHOW_ERROR } from "../../utils/toastMessages";

const initialState = {
  currentUser: {},
  permissions: [],
  email: {},
  check: true,
  Authenticate: false,
  status: "idle",
};

export const getUserData = createAsyncThunk(
  "auth/user",
  async (data, thunkAPI) => {
    // //console.log("from get user function :", data);
    try {
      let getPermissions;
      const userDataResponse = await API.auth.user(data.token);

      if (userDataResponse.data.success) {
        // console.log("user data :", userDataResponse.data.data);

        // //console.log("user Role Id not found! ");
        if (userDataResponse.data.data.company) {
          getPermissions = await API.Permissions.getAllAssigned(
            userDataResponse.data.data?.role[0]?.id
          );
        } else {
          getPermissions = await API.Permissions.getAllAssigned(
            userDataResponse.data.data?.role[0]?.id
          );
        }

        return {
          success: userDataResponse.data.success,
          userData: userDataResponse.data.data,
          token: data.token,
          navigate: data.navigate,
          from: data.from,
          permissions: getPermissions?.data?.data || {},
        };
      }
    } catch (error) {
      // //console.log(error);
      const { code, success, message, errors } = error.response.data;
      if (!success && code === 400) {
        SHOW_ERROR(true, message);
      } else if (!success && code === 422) {
        SHOW_ERROR(true, Object.entries(errors)[0][1][0]);
      } else {
        SHOW_ERROR(true, ERROR_MESS);
        thunkAPI.dispatch(logoutUser());
        data.navigate("/login");
        SHOW_ERROR(true, "please login again !");
      }

      return {
        success: false,
        userData: {},
        token: null,
        navigate: null,
        from: null,
        permissions: {},
      };
    }
  }
);

export const authLogIn = createAsyncThunk(
  "auth/login",
  async (userData, thunkAPI) => {
    try {
      const res = await API.auth.login(userData.loginData);
      const { access_token } = res.data;
      if (access_token) {
        // userData.navigate(userData.from);
        axios.defaults.headers.common = {
          Authorization: `Bearer ${access_token}`,
        };
        thunkAPI.dispatch(
          getUserData({
            token: access_token,
            navigate: userData.navigate,
            from: userData.from,
          })
        );

        // //console.log("hello", res.data);

        return {
          success: true,
          data: res.data,
          code: 200,
        };
      }
    } catch (error) {
      // // // //console.log(error.response.data.message);
      const { code, success, message, errors } = error.response.data;
      if (!success && code === 400) {
        SHOW_ERROR(true, message);
      } else if (!success && code === 422) {
        SHOW_ERROR(true, Object.entries(errors)[0][1][0]);
      } else {
        SHOW_ERROR(true, ERROR_MESS);
      }
      return {
        success,
        data: {},
        code,
      };
    }
  }
);

export const getCompanyId = createAsyncThunk("get/companyId", async (id) => {
  const response = id;
  return response;
});

export const passwordReset = createAsyncThunk(
  "auth/passwordReset",
  async (data) => {
    try {
      const res = await API.auth.passwordUpdate(data);
      if (res.data.success) {
        toast.success("Updated Successfully!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
      // //console.log(res.data);
      return res.data;
    } catch (error) {
      const { code, success, message, errors } = error.response.data;

      if (!success && code === 400) {
        SHOW_ERROR(true, message);
      } else if (
        !success &&
        code === 422 &&
        errors.hasOwnProperty("password")
      ) {
        SHOW_ERROR(true, errors.password[0]);
      } else {
        SHOW_ERROR(true, message);
      }
      // //console.log(error);
      return error.res;
    }
  }
);

export const regPage = createAsyncThunk('auth/regPage', async (data) => {
  //   try {
  //     const res = await API.auth.forgotPassword(data.mail);
  //     return data.mail;
  //   } catch (error) {
  //     const { code, success, message, errors } = error.response.data;
  //     if (!success && code === 400) {
  //       SHOW_ERROR(true, message);
  //     } else if (
  //       !success &&
  //       code === 422 &&
  //       errors.hasOwnProperty('password')
  //     ) {
  //       SHOW_ERROR(true, errors.password[0]);
  //     } else {
  //       SHOW_ERROR(true, message);
  //     }
  //     // //console.log(error);
  //     return error.res;
  //   }
});
export const forgotPassowrd = createAsyncThunk(
  "auth/forgotPassword",
  async (data) => {
    try {
      const res = await API.auth.forgotPassword(data.mail);
      return data.mail;
    } catch (error) {
      const { code, success, message, errors } = error.response.data;

      if (!success && code === 400) {
        SHOW_ERROR(true, message);
      } else if (
        !success &&
        code === 422 &&
        errors.hasOwnProperty("password")
      ) {
        SHOW_ERROR(true, errors.password[0]);
      } else {
        SHOW_ERROR(true, message);
      }
      // //console.log(error);
      return error.res;
    }
  }
);

export const verifyResetCode = createAsyncThunk(
  "auth/verisyCode",
  async (data) => {
    try {
      const res = await API.auth.verifyResetCode(data.code);
      // console.log(res.data);
      if (res.data.success) {
        data.navigate("/newPass");
      }
      return res.data;
    } catch (error) {
      const { code, success, message, errors } = error.response.data;

      if (!success && code === 400) {
        SHOW_ERROR(true, message);
      } else if (!success && code === 422 && errors.hasOwnProperty("email")) {
        SHOW_ERROR(true, errors.email[0]);
      } else {
        SHOW_ERROR(true, message);
      }
      // //console.log(error);
      return error.res;
    }
  }
);

export const resetPassword = createAsyncThunk(
  "auth/resetPassword",
  async (data) => {
    try {
      const res = await API.auth.resetPassword(data.pass);
      if (res.data.success) {
        data.navigate("/login");
        toast.success("Updated Successfully!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
      // //console.log(res.data);
      return res.data;
    } catch (error) {
      const { code, success, message, errors } = error.response.data;

      if (!success && code === 400) {
        SHOW_ERROR(true, message);
      } else if (
        !success &&
        code === 422 &&
        errors.hasOwnProperty("password")
      ) {
        SHOW_ERROR(true, errors.password[0]);
      } else {
        SHOW_ERROR(true, message);
      }
      // //console.log(error);
      return error.res;
    }
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setCompnyId: {
      reducer(state, action) {
        state.companyId = action.payload;
      },
    },
    logoutUser: {
      reducer(state, action) {
        state.Authenticate = false;
        state.status = "done";
        state.currentUser = {};
        state.permissions = [];
        removeToken();
        removeCompantId();
      },
    },
  },
  extraReducers(builder) {
    builder
      .addCase(authLogIn.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(authLogIn.fulfilled, (state, action) => {
        const { success, data, code } = action.payload;
        if (success && code === 200) {
          // state.status = "succeeded";
          if (data.access_token) {
            setToken(data.access_token);
          }
          if (data?.companyId) {
            setCompantId(data.companyId);
          }
        } else {
          state.status = "failed";
        }
      })
      .addCase(authLogIn.rejected, (state, action) => {
        state.status = "failed";
        // //console.log("from fail request : ", action.payload);
        SHOW_ERROR(true, "please check your internet connection");
      })
      .addCase(getUserData.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getUserData.fulfilled, (state, action) => {
        const { success } = action.payload;
        // //console.log("user function got called :", action.payload);
        if (success) {
          state.status = "succeeded";
          if (action.payload.token) {
            setToken(action.payload.token);
          }
          if (action.payload.userData.company) {
            setCompantId(action.payload.userData.company);
          }

          if (
            action.payload.from === "/login" ||
            action.payload.from === undefined
          ) {
            if (!action.payload.userData.company) {
              action.payload.navigate("/company");
            } else {
              action.payload.navigate("/");
            }
          } else {
            action.payload.navigate(action.payload?.from);
          }
          state.currentUser = action.payload?.userData;

          if (
            action.payload?.userData &&
            action.payload?.permissions?.assignedPermissions !== undefined
          ) {
            for (const [key, value] of Object.entries(
              action.payload?.permissions?.assignedPermissions
            )) {
              state.permissions.push(value?.trim());
            }
          }
          state.Authenticate = true;
        } else {
          state.status = "failed";
          state.Authenticate = false;
        }
      })
      .addCase(getUserData.rejected, (state, action) => {
        state.status = "failed";
        // //console.log("from fail request : ", action.payload);
        // SHOW_ERROR(true, "please check your internet connection");
        // state.error = action.payload?.message;
      })
      .addCase(passwordReset.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(passwordReset.fulfilled, (state, action) => {
        state.status = "succeeded";
      })
      .addCase(passwordReset.rejected, (state, action) => {
        state.status = "failed";
        // //console.log("from fail request : ", action.payload);
      })

      .addCase(getCompanyId.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getCompanyId.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.companyId = action.payload;
      })
      .addCase(getCompanyId.rejected, (state, action) => {
        state.status = "failed";
        // //console.log(action.error);
        state.error = action.payload;
      })

      .addCase(forgotPassowrd.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(forgotPassowrd.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.email = action.payload.email;
        state.check = false;
      })
      .addCase(forgotPassowrd.rejected, (state, action) => {
        state.status = "failed";
      })

      .addCase(verifyResetCode.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(verifyResetCode.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.check = true;
      })
      .addCase(verifyResetCode.rejected, (state, action) => {
        state.status = "failed";
      })

      .addCase(resetPassword.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(resetPassword.fulfilled, (state, action) => {
        state.status = "succeeded";
      })
      .addCase(resetPassword.rejected, (state, action) => {
        state.status = "failed";
      });
  },
});

export const { logoutUser, setCompnyId } = authSlice.actions;

const { reducer } = authSlice;
export default reducer;
