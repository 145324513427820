import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import { ImTree } from 'react-icons/im';
import { useSelector } from 'react-redux';
import '../../../assets/css/main.css';
const LinkItem = ({
  link,
  label,
  icon,
  permission,
  subMenuKey,
  changeStateHandler,
  subExist,
}) => {
  const { permissions } = useSelector((state) => state.Auth);
  console.log('subtext', subExist);
  return (
    <>
      {permissions?.includes(permission) ? (
        <NavLink to={link} className="menu-link  activeHeight">
          {icon.includes('svg') ? (
            <img
              src={icon}
              className="menu-icon tf-icons svg-icon"
              alt="sidebar menu icon"
            />
          ) : label.includes('Build Area') ? (
            <ImTree className="menu-icon tf-icons " />
          ) : (
            <i className={icon} />
          )}
          <div
            style={{
              display: `flex`,
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            {label}
            {/* {subExist && <IoIosArrowDown/>} */}
          </div>
        </NavLink>
      ) : null}
    </>
  );
};

export default LinkItem;
