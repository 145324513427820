import { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
// local imports
import AppRoutes from "./routes";

import StaticSiteRoutes from "./site/routes";
// const createGuest = require("cross-domain-storage/guest");

function App() {
  const { pathname } = useLocation(); // <-- get current location being accessed

  useEffect(() => {
    if (pathname?.includes("login/")) {
      const pathArray = pathname?.split("/");
      if (pathArray?.length === 3) {
        localStorage.setItem("access_token", pathArray[pathArray?.length - 1]);
      }
    }
    // window?.addEventListener("beforeunload", () => "hello from ");
    // return () => {};
  }, []);

  if (pathname?.includes("/register")) {
    return <StaticSiteRoutes />;
  } else {
    return <AppRoutes />;
  }
}

export default App;
